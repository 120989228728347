<template>
  <div id="wrap">
    <div class="login-title success">
      <p>和世界最先进 chatGPT</p>
      <p class="login-title-desc">对话不限量</p>
    </div>
    <div class="success-box">
      <div class="success-button">马上下载妙语 AI App</div>
    </div>
    <div class="success-desc">
      <p class="success-desc-title">使用步骤</p>
      <p class="success-desc-text">1.下载妙语 AI App</p>
      <p class="success-desc-text">2.使用接受邀请时的微信账号登录</p>
    </div>
    <div class="login-title invited">
      <p>该账号已被邀请</p>
      <p>无法再次获得妙语 AI VIP</p>
    </div>
    <div class="login-desc invited">
      <p>下载妙语 AI App，使用接受邀请时的</p>
      <p>微信账号登录即可使用</p>
    </div>
    <div class="login-title registered">
      <p>该账号已注册</p>
      <p>无法再次获得妙语 AI VIP</p>
    </div>
    <div class="login-desc registered">
      <p>下载妙语 AI App，使用接受邀请时的</p>
      <p>微信账号登录即可使用</p>
    </div>
    <div class="login-title Obtained">
      <p>该账号已领取</p>
      <p>无法再次获得妙语 AI VIP</p>
    </div>
    <div class="login-desc Obtained">
      <p>下载妙语 AI App，使用接受邀请时的</p>
      <p>微信账号登录即可使用</p>
    </div>
    <div class="login-box use-box">
      <div class="login-box-title">使用步骤</div>
      <div class="login-box-text">1、下载<i>妙语 AI App</i></div>
      <div class="login-box-text">2、使用<i>接受邀请时的微信账号登录</i></div>
    </div>
    <div id="apple-button" class="wx-login">下载妙语 AI App 使用 VIP</div>
  </div>
</template>

<script>
import $ from 'jquery'; // 导入 jQuery

export default {
  data() {
    return {};
  },
  mounted() {
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        WeixinJSBridge.invoke("setFontSizeCallback", {
          fontSize: "2",
        });
      },
      false
    );
    $("#apple-button").click(function () {
      // 获取用户代理字符串
      let authUrl;
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        authUrl = `https://itunes.apple.com/cn/app/6459354709?mt=8`;
        window.location.href = authUrl;
      } else if (/Android/.test(userAgent)) {
        $(location).attr("href", `https://quip.weixuanpro.cn/h5/wxdownload.html`);
      } else {
        console.log("这是其他设备");
      }
    });
    $(".success-button").click(function () {
      // 获取用户代理字符串
      let authUrl;
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        authUrl = `https://itunes.apple.com/cn/app/6459354709?mt=8`;
        window.location.href = authUrl;
      } else if (/Android/.test(userAgent)) {
        $(location).attr("href", `https://quip.weixuanpro.cn/h5/wxdownload.html`);
      } else {
        console.log("这是其他设备");
      }
    });

    const cssLink = document.getElementById("css-link");
    const cssLink2 = document.getElementById("css-link2");

    // 获取当前的时间戳
    const timestamp = new Date().getTime();


    // 处理微信登录回调
    const urlParams = new URLSearchParams(window.location.search);

    console.log("window.location.search", window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const invitorTiantiId = urlParams.get("invitorTiantiId");
		let testcode;

    // $('.success').css({
    // 	'display': 'block'
    // })
    // $('.success-desc').css({
    // 	'display': 'block'
    // })
    // $('.success-box').css({
    // 	'display': 'flex'
    // })
    // $("#wrap").addClass("active");

		// console.log(code,state,'33333333')

    if (code && state === "STATE") {
      // 发送 code 到后端，后端负责获取用户信息
      // 后端处理成功后，前端可以跳转或显示登录成功页面
      console.log("获取到微信登录的 code：", code);
      testcode = code;

      let importTablesReq = {
        code: code, // 微信code码
        invitorId: invitorTiantiId, // 邀请人ID 非必填
      };

      $.ajax({
        type: "POST", // 请求的方式，例如 GET 或 POST
        url: "https://quip.weixuanpro.cn/api/h5/v1/user/invite/h5/wx", // 请求的 URL 地址
        // url: 'https://quip.airmx.com/api/h5/v1/user/invite/h5/wx', // 请求的 URL 地址
        contentType: "application/json; charset=utf-8",
				headers: {
          from: 1,
          version: "1.1.0",
          serial: new Date().getTime() + "",
        },
        data: JSON.stringify(importTablesReq), // 这次请求要携带的数据
        success: function (res) {
					let resValue = res.data;
					localStorage.setItem("H5visitorId", resValue.visitorId);
        	localStorage.setItem("H5identification", resValue.identification);
        	localStorage.setItem("H5token", resValue.token);
          if (res.code == 10301) {
            console.log("用户已注册");
            $(".registered").css({
              display: "block",
            });
            $(".use-box").css({
              display: "block",
            });
            $(".wx-login").css({
              display: "flex",
            });
          } else if (res.code == 10302) {
            console.log("用户已邀请");
            $(".invited").css({
              display: "block",
            });
            $(".use-box").css({
              display: "block",
            });
            $(".wx-login").css({
              display: "flex",
            });
          } else if (res.code == 10303) {
            console.log("用户已获取过VIP试用");
            $(".Obtained").css({
              display: "block",
            });
            $(".use-box").css({
              display: "block",
            });
            $(".wx-login").css({
              display: "flex",
            });
          } else {
            console.log("成功");
            $(".success").css({
              display: "block",
            });
            $(".success-desc").css({
              display: "block",
            });
            $(".success-box").css({
              display: "flex",
            });
            $("#wrap").addClass("active");
          }
        },
        fail: function (res) {
          $.ajax({
            type: "POST", // 请求的方式，例如 GET 或 POST
            url: "https://quip.weixuanpro.cn/api/h5/v1/user/invite/h5/wx", // 请求的 URL 地址
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(importTablesReq), // 这次请求要携带的数据
            success: function (res2) {
              if (res2.code == 10301) {
                console.log("用户已注册");
                $(".registered").css({
                  display: "block",
                });
                $(".use-box").css({
                  display: "block",
                });
                $(".wx-login").css({
                  display: "flex",
                });
              } else if (res2.code == 10302) {
                console.log("用户已邀请");
                $(".invited").css({
                  display: "block",
                });
                $(".use-box").css({
                  display: "block",
                });
                $(".wx-login").css({
                  display: "flex",
                });
              } else if (res2.code == 10303) {
                console.log("用户已获取过VIP试用");
                $(".Obtained").css({
                  display: "block",
                });
                $(".use-box").css({
                  display: "block",
                });
                $(".wx-login").css({
                  display: "flex",
                });
              } else {
                console.log("成功");
                $(".success").css({
                  display: "block",
                });
                $(".success-desc").css({
                  display: "block",
                });
                $(".success-box").css({
                  display: "flex",
                });
                $("#wrap").addClass("active");
              }
            },
          });
        },
      });
    }
  },
};
</script>

<style scoped lang="stylus">

@font-face {
  font-family: 'SourceHan'; /* 为字体定义一个名称 */
  src: url('/src/assets/SourceHanSansCN-Medium_1.woff') format('woff'); /* 指定字体文件的路径和格式 */
  /* 可以添加更多的格式和路径，以确保跨浏览器兼容性 */
}

* {
  font-family: 'SourceHan','PingFang SC' !important; /* 使用您定义的字体名称 */
}

#wrap {
	width: 100%;
	height: 100vh;
	background-color: #202123;
	overflow: hidden;
	position: relative;
}
#wrap.active{
	background: linear-gradient(180deg, #BBD5EE 0%, #F1E6D5 100%);
}
.login-title{
	color: #FFF;
	text-align: center;
	font-family: PingFang SC;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 36px */
	margin: 90px auto 32px;
	display: none;
}
.login-title p{
	font-weight: 500;
}
.login-desc{
	color: rgba(255, 255, 255, 0.70);
	text-align: center;
	font-family: PingFang SC;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
	margin: 0px auto 40px;
	display: none;
}
.wx-login{
	display: none;
	width: 320px;
	height: 60px;
	justify-content: center;
	align-items: center;
	gap: 2px;
	border-radius: 12px;
	background: #17CE92;
	margin: 0 auto;
	color: #FFF;
	text-align: center;
	font-family: Source Han Sans CN;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 16px */
	margin-bottom: 16px;
	cursor: pointer;
	animation: bounce 1s ease-in-out infinite;
}
@keyframes bounce {
  0%, 100% {
    transform: scale(1); /* 初始和结束状态为正常大小 */
  }
  50% {
    transform: scale(1.05); /* 中间状态为放大 */
  }
}
.button-img{
	width: 24px;
	height: 24px;
	margin-right: 4px;
}
.login-box{
	display: none;
	width: 320px;
	box-sizing: border-box;
	padding: 24px;
	border-radius: 12px;
	background: rgba(255, 255, 255, 0.06);
	margin: 0 auto 40px;
}
.login-box-title{
	color: #17CE92;
	text-align: center;
	font-family: PingFang SC;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 30px */
}
.login-box-text{
	color: rgba(255, 255, 255, 0.70);
	font-family: Source Han Sans CN;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 25.6px */
	margin-top: 16px;
}
.login-box-text i{
	font-style: normal;
	color: #17CE92;
}
.use-box{
	display: none;
}

.login-title.success{
	color: #000;
	text-align: center;
	font-family: Source Han Sans CN;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 24px */
	text-transform: capitalize;
	margin-top: 24px;
}
.login-title .login-title-desc{
	color: #000;
	text-align: center;
	font-family: Source Han Sans CN;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 32px */
	text-transform: uppercase;
	margin-top: 12px;
}

.success-box{
	display: none;
	width: 315px;
	height: 386px;
	padding: 10px 10px 24px 10px;
	position: relative;
	margin: 8px auto 40px;
	border-radius: 12px;
	background-image: url('../assets/success.png');
	background-size: 100% 100%;
	box-sizing: border-box;
}
.success-box-wrap{
	width: 313px;
	height: 167px;
	background: #FFEAB2;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.success-box-title{
	color: #9A5300;
	font-family: Source Han Sans CN;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
	margin-bottom: 23px;
}
.success-box-text{
	color: #FF2B2B;
	font-family: Source Han Sans CN;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 36px */
	margin-bottom: 18px;
}
.success-box-desc{
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 200px;
	background: #DBC48A;
	color: #9A5300;
	font-family: Source Han Sans CN;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}
.success-desc-title{
	color: #1F1F1F;
	font-family: Source Han Sans CN;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 200%; /* 36px */
	text-transform: capitalize;
}
.success-desc{
	display: none;
	width: 312px;
	border-radius: 8px;
	background: #FFF;
	color: #1F1F1F;
	padding: 16px 32px;
	box-sizing: border-box;
	font-family: PingFang SC;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 200%; /* 32px */
	margin: 0 auto;
}

.success-desc .success-desc-text{
	/* margin-top: 8px; */
}
.success-button{
	width: 268px;
	height: 52px;
	border-radius: 100px;
	background: linear-gradient(180deg, #FFE49B 0%, #FFB900 100%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	color: #BD2E1D;
	text-align: center;
	font-family: PingFang SC;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 20px */
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 63px;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: resize 1s infinite linear; /* 使用关键帧动画 */
	transform-origin: center; /* 设置变换基点为中心 */
}

@keyframes resize {
  0% {
    transform:  translateX(-50%) scale(1); /* 初始大小 */
  }
  50% {
    transform:translateX(-50%) scale(1.05); /* 放大 1.2 倍 */
  }
  100% {
    transform:translateX(-50%) scale(1); /* 回到初始大小 */
  }
}

@media (max-height: 699px) {
	.login-title.success{
		font-size: 18px;
	}
	.login-title .login-title-desc{
		font-size: 24px;
	}
	.success-box{
		display: none;
		width: 316px;
		height: 306px;
		padding: 10px 10px 24px 10px;
		position: relative;
		margin: 8px auto 40px;
		border-radius: 12px;
		background-image: url('../assets/success2.png');
		background-size: 100% 100%;
		box-sizing: border-box;
	}
	.success-button{
		bottom: 31px;
	}
}
</style>

<template>
  <div class="login-box">
    <div class="content-box">
      {{text}}
    </div>
    <div class="button" @click="navToDownload">
      开通 SVIP，体验更强大的 GPT-4 Turbo 
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text:`▪大规模知识库：GPT-4 Turbo 的知识库包含大规模互联网文本，超过 1000 亿参数，每一个参数都代表着学到的某种信息或关联。

▪ 高通过率：GPT-4 Turbo 曾经自主学习并通过了十几个高中的期末考试，可以达到美国顶级大学的录取分数。

▪ 创新能力：不仅仅是复制旧的信息，GPT-4 Turbo 还能创造全新的文本。如需创新品牌标语、广告词甚至是小说，GPT-4 Turbo 均能为你取得胜利。

▪ 优秀的编程助手：如果你是程序员，那么GPT-4 Turbo 就是你的贴心助手。根据你的输入，GPT-4 Turbo 可以帮你生成代码，让你轻松解决各种问题。

▪自适应学习：GPT-4 Turbo 可以自我学习，适应你的输入方式和风格，以更贴合你的需求产出结果。

▪ 真实交互：GPT-4 Turbo 能提供真实的对话交互。若你在启程旅行，或许GPT-4 Turbo 就会是你随身的语言翻译伙伴，让你在异国他乡也能像当地人一样交流。

▪复杂任务处理：GPT-4 Turbo 可以帮你处理写作、翻译、概括文章甚至编程等复杂任务。图片一下你准备考试，GPT-4 Turbo 可以做你私人的题库，帮你备考。

▪ 有效的工作助手：当你需要编写报告或是整理数据时，GPT-4 Turbo 也可以扮演得力的助手角色，高效有序地完成各项任务。

▪高精准度预测：经过严格的测试，GPT-4 Turbo 拥有过人的预测性能，其预测文本的成功率可以达到惊人的 90% 以上。

▪流畅的多语言理解和生成:GPT-4 Turbo 可以处理 20多种不同的语言，无论你需要翻译还是创作多语言内容，GPT-4 Turbo 都能做得游刃有余。`
    }
  },
  methods:{
    navToDownload(){
      window.open('https://quip.weixuanpro.cn/h5/weixinDownload.html', '_self');
    }
  }
}
</script>

<style scoped lang="stylus">

@font-face {
  font-family: 'SourceHan'; /* 为字体定义一个名称 */
  src: url('/src/assets/SourceHanSansCN-Medium_1.woff') format('woff'); /* 指定字体文件的路径和格式 */
  /* 可以添加更多的格式和路径，以确保跨浏览器兼容性 */
}

* {
  font-family: 'SourceHan','PingFang SC' !important; /* 使用您定义的字体名称 */
}
.login-box{
  width:100%;
  min-height:100vh;
  background-color: #202123;
  position: relative;
  // overflow: hidden;
  overflow: scroll;
}
.content-box{
  width: 345px;
  margin:8px auto 24px;
  color: rgba(255, 255, 255, 0.70);
  /* 正文 32 160% */
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  white-space:pre-line;
}
.button{
  display: flex;
  width: 345px;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #17CE92;
  color: #FFF;
  text-align: center;
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  margin: 0 auto 34px;
  cursor pointer;
}
</style>
/**
 * @file
 * @description 接口map
 * */
 
const apiMap = {
	host: 'https://quip.joychatai.com',
	// host: 'https://quip.airmx.com',
	url: 'https://airmx-cool-online.oss-cn-beijing.aliyuncs.com' // 线上服务器的URL。
	// url: 'https://airmx-cool.oss-cn-beijing.aliyuncs.com' || uploadRet.upInfo.url, // 开发者服务器的URL。
}

const appMsg = {
	http: '88f999e5be064b7f495ff79d5710f47e',
  websocket: '88f999e5be064b7f495ff79d5710f47e',
	h5socket:'88f999e5be064b7f495ff79d5710f47e',
	h5key:'88f999e5be064b7f495ff79d5710f47e'
}

export {
    apiMap,
	  appMsg
}